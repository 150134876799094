@import '../../../assets/styles/mixins';

.about {
	padding: 20vh 20vw 20vh;

	font-size: 2vw;

	width: 100%;

	user-select: none;

	@include respond(phone) {
		padding: 13vh 0 4vh;
		font-size: 4.4vw;
	}
}
