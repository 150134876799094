$border-radius-v-big: 50px;
$border-radius-big: 30px;
$border-radius: 18px;
$border-radius-small: 8px;

$color-primary: #f29e0e;
$color-primary-dark: #c88436;
$color-secondary: #b54dae;

$color-white: #fff;
$color-white-dark: #f4f4f4;
$color-black: #000;
$color-black-light: #0f0f0f;

$box-shadow: 0 0.1rem 0.7rem rgba($color-white-dark, 0.1);
$box-shadow-focus: 0 0.1rem 0.7rem rgba($color-white-dark, 0.2);

$margin-card-bottom: 1rem;
