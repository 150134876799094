@import '../../../assets/styles/variables.scss';

.link {
	position: relative;
	text-decoration: none;

	&::before {
		position: absolute;
		display: block;
		content: '';
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;

		background-color: white;
		transition: all 250ms ease;
	}

	span {
		display: inline-block;
		z-index: 3;
		min-width: 1vw;
	}

	& > .outer {
		transition: transform 350ms ease;
	}

	&:hover {
		&::before {
			opacity: 0;
		}

		& > .outer {
			transition-duration: 800ms;

			& > .inner {
				animation: float 4s ease infinite;

				& > .letter {
					background: linear-gradient(
						to right,
						$color-white-dark,
						$color-primary,
						$color-white-dark
					);
					background-size: 1000%;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					animation: background-pan 100s linear infinite;
				}
			}

			&:nth-child(1) {
				transform: translate(-40%, 20%) rotate(7deg);
			}

			&:nth-child(2) {
				transform: translate(-40%, 20%) rotate(4deg);
			}

			&:nth-child(3) {
				transform: translate(-10%, 60%) rotate(-9deg);
			}

			&:nth-child(4) {
				transform: translate(0%, 8%) rotate(-11deg);
			}

			&:nth-child(5) {
				transform: translate(0%, -20%) rotate(5deg);
			}

			&:nth-child(6) {
				transform: translate(0%, 20%) rotate(-4deg);
			}

			&:nth-child(7) {
				transform: translate(0%, 0%) rotate(0deg);
			}

			&:nth-child(8) {
				transform: translate(0%, -30%) rotate(8deg);
			}

			&:nth-child(9) {
				transform: translate(0%, -20%) rotate(-5deg);
			}

			&:nth-child(10) {
				transform: translate(0%, 15%) rotate(2deg);
			}

			&:nth-child(11) {
				transform: translate(50%, -10%) rotate(-1deg);
			}

			&:nth-child(12) {
				transform: translate(40%, -30%) rotate(-14deg);
			}

			&:nth-child(13) {
				transform: translate(20%, 20%) rotate(-6deg);
			}

			&:nth-child(14) {
				transform: translate(20%, 40%) rotate(7deg);
			}

			&:nth-child(15) {
				transform: translate(-30%, -20%) rotate(9deg);
			}
		}
	}
}

@keyframes float {
	from,
	to {
		transform: translateY(-0%);
	}

	50% {
		transform: translateY(-9%);
	}
}

@keyframes background-pan {
	from {
		background-position: 1000% center;
	}

	to {
		background-position: 0% center;
	}
}
