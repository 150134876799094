@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.header {
	@include wrapper;

	position: fixed;
	top: 6vh;
	left: 0;
	right: 0;

	z-index: 1;

	display: flex;
	justify-content: space-between;

	&__logo {
		opacity: 1;
		letter-spacing: 0.4px;
		font-size: 1.7rem;
		text-decoration: none;
		color: $color-white-dark;

		z-index: 9999;
	}

	&__nav {
		@include respond(tab-land) {
			display: none;
		}

		a {
			@include link;

			color: rgba($color: $color-white-dark, $alpha: 0.5);

			position: relative;

			font-size: 1.7rem;

			&.active {
				&:after {
					width: 100%;
					transition: 0.3s ease-out width;
				}
			}

			&:after {
				font-size: 1.7rem;
				line-height: 1.4;

				content: attr(data-hover);
				position: absolute;
				top: 0;
				left: 0;

				color: $color-white-dark;
				width: 0;
				overflow: hidden;
				white-space: nowrap;

				transition: 0.3s ease-out width;
			}

			&:hover:after {
				width: 100%;
				transition: 0.3s ease-in width;
			}

			&:not(:last-child) {
				margin-right: 2.5rem;
			}
		}
	}

	&__mobileMenu {
		display: none;

		@include respond(tab-land) {
			position: relative;

			display: block;
			z-index: 9998;
		}

		&.open .overlay {
			height: 200vh;
			opacity: 1;
		}

		.overlay {
			position: fixed;
			display: none;
			top: 0;
			left: 0;
			width: 100vw;
			height: 0;
			background-color: rgba(0, 0, 0, 1);
			opacity: 0;
			transition: opacity 1s;

			@include respond(tab-land) {
				display: block;
			}
		}

		nav {
			a {
				color: $color-white-dark;
				text-decoration: none;
			}

			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: calc(var(--vh, 1vh) * 100);
			overflow-y: auto;

			transition: visibility 0.7s ease-in-out, opacity 0.7s ease-in-out,
				height 0.1s;

			font-size: 6vw;
			line-height: 1.8;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			background-color: $color-black;

			opacity: 1;
			visibility: visible;
		}
	}

	&__hamburger {
		cursor: pointer;
		height: 100%;

		position: relative;
		z-index: 9999;

		span {
			display: block;
			width: 33px;
			height: 2px;
			outline: none;

			&:not(:last-child) {
				margin-bottom: 7px;
			}

			position: relative;

			background: $color-white-dark;

			transform-origin: 2px 0px;

			transition: transform 0.9s cubic-bezier(0.77, 0.2, 0.05, 1),
				background 0.9s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.95s ease;

			&:first-child {
				transform-origin: 0% 0%;
			}

			&:nth-last-child(2) {
				transform-origin: 0% 100%;
			}
		}

		&__open {
			& span:nth-child(1) {
				transform: rotate(45deg) translate(0, -4px);
			}

			& span:nth-child(2) {
				opacity: 0;
			}

			& span:nth-child(3) {
				transform: rotate(-45deg) translate(0, 2px);
			}
		}
	}
}
