@import '../../../assets/styles/mixins';

.portfolio {
	padding-top: 15vh;

	@include respond(phone) {
		padding-top: 13vh;
	}

	&__cards {
		width: 105%;
		margin-left: -2.5%;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;

		& > div {
			flex-basis: 45%;
			padding-bottom: 45%;

			margin-bottom: 5%;

			@include respond(phone) {
				flex-basis: 100%;
				padding-bottom: 100%;
			}
		}
	}
}
