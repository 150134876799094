@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.scroll {
	svg {
		fill: $color-white-dark;
		width: 2.5rem;
		height: 2.5rem;
		display: inline-block;
	}

	height: 2.5rem;

	position: fixed;
	display: flex;
	bottom: 6vh;
	right: 5.25vw;
	line-height: 2.5rem;
	text-decoration: none;
	color: $color-white-dark;
	opacity: 0.5;
	user-select: none;

	transition: opacity 0.3s ease-in-out;

	&:hover {
		opacity: 1;
	}

	@include respond(phone) {
		bottom: 2rem;
		right: 2rem;
	}
}
