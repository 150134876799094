@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.hero {
	position: relative;

	height: 100vh;

	h1 {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		padding: 0 11.5vw;
		font-size: 5.5vw;
		line-height: 1.2;
		letter-spacing: 0.4px;
		user-select: none;

		@include respond(phone) {
			padding: 0;
			font-size: 7.2vw;
		}
	}
}
