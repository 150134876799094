@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.heading {
	display: flex;
	align-items: flex-end;
	width: 100%;

	margin-bottom: 3rem;

	h2 {
		font-size: 3vw;

		line-height: 1;

		@include respond(phone) {
			font-size: 7vw;
		}
	}

	&__line {
		width: 100%;
		margin: 0 2rem;

		div {
			width: 100%;
			height: 1px;
			background-color: $color-white-dark;
		}
	}

	p {
		white-space: nowrap;
		font-size: 1.5vw;
		line-height: 1;
		text-align: right;

		@include respond(phone) {
			font-size: 4.2vw;
		}
	}
}
