@import 'mixins';
@import 'fonts';
@import 'variables';

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	max-width: 100%;
	overflow-x: clip;
}

html {
	scroll-behavior: smooth;

	font-size: 62.5%; // 1rem = 10px

	@include respond(tab-land) {
		font-size: 56.25%;
	}

	@include respond(tab-port) {
		font-size: 50%;
	}

	@include respond(big-desk) {
		font-size: 75%;
	}
}

body {
	max-width: 100%;
	position: relative;
	box-sizing: border-box;

	font-family: 'Radikal', sans-serif;
	font-size: 1.4rem;
	line-height: 1.6;
	hyphens: auto;
	background: $color-black;
	color: $color-white-dark;
}

h1 {
	font-family: 'Radikal', sans-serif;
	font-weight: 400;
}

h2,
h3,
h4,
h5,
h6 {
	font-family: 'Radikal', sans-serif;
	font-weight: 700;
}

h1 {
	font-size: 4.6rem;
}

h2 {
	font-size: 3.6rem;
}

h3 {
	font-size: 2.8rem;
}

h4 {
	font-size: 2.2rem;
}

h5 {
	font-size: 1.8rem;
}

h6 {
	font-size: 1.6rem;
}

.fixed-wrapper {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	z-index: 100;
}
