@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins';

$roundScale: 1.2;
$roundScalePhone: 1.4;
$baseSize: 40vw;
$baseSizePhone: 90vw;

.root {
	display: block;
	position: relative;

	min-height: 2000px;

	margin: 40vh auto 5vh;

	@include respond(phone) {
		margin: 30vh auto 10vh;
	}

	&__container {
		position: sticky;
		top: 20%;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;

		width: $baseSize;
		height: $baseSize;

		@include respond(phone) {
			width: $baseSizePhone;
			height: $baseSizePhone;
			top: 30%;
		}
	}
}

.backgroundCircle {
	position: absolute;

	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	transition: transform 0.3s ease-out;

	&__circle {
		position: relative;
		width: $baseSize;
		height: $baseSize;
		border: dashed 1px white;
		border-radius: 50%;
		z-index: -1;
		opacity: transform 0.3s ease-out;

		@include respond(phone) {
			width: $baseSizePhone;
			height: $baseSizePhone;
		}

		p {
			position: absolute;
			font-size: 1.7rem;
			width: 100%;
			height: 100%;
			text-align: center;

			&:nth-child(1) {
				transform: scale($roundScale);

				@include respond(phone) {
					transform: scale($roundScalePhone);
				}
			}

			&:nth-child(2) {
				transform: rotate(120deg) scale($roundScale);

				@include respond(phone) {
					transform: rotate(120deg) scale($roundScalePhone);
				}
			}

			&:nth-child(3) {
				transform: rotate(240deg) scale($roundScale);

				@include respond(phone) {
					transform: rotate(240deg) scale($roundScalePhone);
				}
			}

			&:nth-child(4) {
				transform: scale($roundScale);

				@include respond(phone) {
					transform: scale($roundScalePhone);
				}
			}
		}
	}
}

.imageContainer {
	position: absolute;
	top: 1.5vw;
	left: 5vw;

	opacity: 0.9;

	width: $baseSize - 10vw;
	height: $baseSize - 3vw;

	@include respond(phone) {
		width: $baseSizePhone - 20vw;
		height: $baseSizePhone - 3vw;
		left: 10vw;
	}

	overflow: hidden;

	border-radius: $border-radius-big;

	img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;

		transition: transform 0.3s ease-out;
		display: block;
	}

	margin: 0;
	z-index: 2;
	background-position: center;
}

.heading {
	position: absolute;
	max-width: 100vw;

	text-transform: uppercase;
	margin: 0;
	font-size: 4vw;

	white-space: nowrap;

	@include respond(phone) {
		font-size: 12vw;
	}

	&__black {
		top: calc(70%);

		z-index: 3;
		color: $color-primary-dark;
		transition: transform 0.3s ease-out, opacity 1.5s ease-out;
	}

	&__white {
		top: calc(70% - 0.6vw);
		left: 5vw;
		z-index: 1;
		transition: transform 0.3s ease-out, opacity 0.5s ease-out;

		@include respond(phone) {
			left: 10vw;
		}
	}
}
