@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: auto;
	padding-bottom: 100%;
	border-radius: $border-radius-v-big;

	@include respond(tab-port) {
		border-radius: $border-radius-big;
	}

	transition: all 0.3s;
	overflow: hidden;

	&__background {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: -1;

		img,
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: 0.9;
		}
	}

	&__content {
		position: absolute;
		left: 5%;
		bottom: 5%;
		width: 90%;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		background-color: rgba($color-black-light, 0.6);
		padding: 3rem 3.5rem;

		border-radius: $border-radius-big;

		@include respond(tab-port) {
			border-radius: $border-radius;

			padding: 2.5rem 2.9rem;
		}

		&__hovered {
			align-items: center;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			background-color: rgba($color-black-light, 0.1);
		}
	}

	h4 {
		font-size: 1.7vw;

		margin-bottom: 1rem;

		@include respond(tab-port) {
			font-size: 2.5vw;
		}

		@include respond(phone) {
			font-size: 5vw;
		}
	}

	p {
		font-size: 1.1vw;
		opacity: 0.85;

		@include respond(tab-port) {
			font-size: 1.4vw;
		}

		@include respond(phone) {
			font-size: 3vw;
		}
	}

	&__tech {
		display: flex;
		justify-content: center;

		flex-wrap: wrap;
		gap: 1rem;
		margin-bottom: 1rem;

		span {
			font-size: 1.1rem;
			padding: 0.5rem 1rem;
			border-radius: $border-radius-small;
			background-color: $color-white-dark;
			color: $color-black-light;
			user-select: none;
		}
	}

	&__links {
		display: flex;
		flex-direction: column;
		gap: 2rem;

		a {
			text-align: center;
			padding: 1.3rem 5rem;
			text-decoration: none;
			border-radius: $border-radius-big;
			background-color: $color-white-dark;
			color: $color-black-light;
			transition: all 0.3s;

			&:hover {
				background-color: $color-primary-dark;
				color: $color-white-dark;
			}
		}
	}
}
