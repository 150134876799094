@import '../../../assets/styles/mixins';

.wrapper {
	@include wrapper;

	display: flex;
	flex-direction: column;
	position: relative;

	min-height: 100vh;
}
