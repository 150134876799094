// media queries w/ breakpoint
// phone, port, land, desk
// 1em = 16px
@mixin respond($breakpoint) {
	@if $breakpoint==phone {
		@media only screen and (max-width: 37.5em) {
			// 600px
			@content;
		}
	}

	@if $breakpoint==tab-port {
		@media only screen and (max-width: 56.25em) {
			// 900px
			@content;
		}
	}

	@if $breakpoint==tab-land {
		@media only screen and (max-width: 75em) {
			// 1200px
			@content;
		}
	}

	@if $breakpoint==big-desk {
		@media only screen and (min-width: 112.5em) {
			// 1800px
			@content;
		}
	}
}

@mixin wrapper {
	width: 100%;
	margin: 0 auto;
	padding: 0 6vw;
}

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin absCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin link {
	text-decoration: none;
	color: inherit;
}
